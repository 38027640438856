@import url("https://fonts.googleapis.com/css2?family=Numans&display=swap");

/* HTML */
html {
  font-size: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
body {
  background-color: var(--pink);
  background-image: url("./images/blurry-gradient-haikei-tall.png");
  background-size: cover;
  color: var(--white);
}
::selection {
  background-color: var(--pink);
}
:root {
  --white: #ffffff;
  --grey: #e0dddd;
  --aqua: #51fdfc;
  --blue: #0072ff;
  --pink: #ef86ec;
  --purple: #5a0162;
  --lavender: #c8c9f0;
  --periwinkle: #8384f2;
}
p {
  color: var(--white);
}
/* Header */
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  gap: 5px;
}
.header-logo {
  height: 50px;
  /* align-self: flex-start; */
}
.header-text {
  font-size: 32px !important;
  font-family: "Numans", sans-serif !important;
  color: var(--aqua);
}
.header-links {
  position: absolute;
  right: 0;
  padding: 0.75rem;
}
.twitter-icon {
  cursor: pointer;
  fill: var(--white) !important;
  transition: all 1s ease !important;
}
.twitter-icon :hover {
  fill: var(--aqua) !important;
}
/* Body */
.container {
  margin: 1rem;
}
.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.MuiMenu-list {
  background: linear-gradient(
    to top right,
    var(--periwinkle),
    var(--pink)
  ) !important;
  padding: 0 !important;
}
.text-input fieldset {
  border-color: var(--white) !important;
}
.text-input label {
  color: var(--white) !important;
}
.MuiSelect-select {
  color: var(--white) !important;
}
.MuiInputBase-input {
  color: var(--white) !important;
}
.text-input svg {
  fill: var(--white);
}
.btn-container {
  margin: 1rem;
  display: flex;
  justify-content: center;
}

.generate-btn {
  cursor: pointer;
  padding: 15px;
  border: 1px solid black;
  border-radius: 20px;
  background: linear-gradient(#00c6ff, #0072ff);
  transition: all 0.2s ease;
  display: inline-block;
  text-align: center;
}
.generate-btn:hover {
  opacity: 0.6;
}
.generate-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.btn-container-2 {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clear-form {
  cursor: pointer;
  padding: 10px;
  border: 1px solid black;
  border-radius: 20px;
  background: linear-gradient(#e136d8, #7c1286);
  transition: all 0.2s ease;
  text-align: center;
}
.clear-form:hover {
  opacity: 0.6;
}

.lines-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  gap: 1rem;
}
.lines {
  padding: 1rem;
  width: 100%;
  background: linear-gradient(#4244e8, #14187e);
  transition: all 0.3s ease;
}
.generate-similar-container {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0 0 0;
}
.generate-similar {
  cursor: pointer;
  background: linear-gradient(#c8c9f0, #858585);
  color: var(--black);
  padding: 0.5rem;
  border-radius: 8px;
  transition: all 0.2s ease;
}
.generate-similar:hover {
  opacity: 0.8;
}
.selected-line {
  padding: 1rem;
  width: 100%;
  background: linear-gradient(#cb371c, #600d07);
}
.line-message {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.line-message p {
  font-size: 20px;
}
